<template>
  <el-dialog
    class="messageDialogBox"
    :visible.sync="visible"
    width="400px"
    :append-to-body="true"
    :show-close="true"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    :custom-class="'messageDialogContainer'"
    :destroy-on-close="true"
  >
    <div class="bindDialog">
      <div class="titleBox">
        <span class="title">更换手机号</span>
        <i class="el-icon-close closeIcon" @click="handleClose"></i>
      </div>
      <div class="content">
        <div class="contentText">更换后即可使用该手机号登录</div>
        <el-form
                :model="ruleForm"
                ref="form"
                @submit.native.prevent
        >
          <el-form-item prop="phoneNumber">
            <div class="modal_item">
              <div class="dropBox">+86</div>
              <el-input v-model="ruleForm.phoneNumber" autocomplete="off" placeholder="请输入手机号码"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="code">
            <div class="code_item">
              <el-input v-model="ruleForm.code" autocomplete="off" placeholder="请输入短信中的验证码"></el-input>
              <button type="button" :class="{'getCodeBtn': true, disabled: isCountDown}" :disabled="isCountDown" @click="getCode">
                {{btnTxt}}
              </button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="btnGroups">
        <button class="rightBtn btn" @click="onClickBind">
          确认更换
        </button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getUnbindCode, unbindPhoneNumber} from "@/api";
import {Message} from "element-ui";

export default {
  name: 'UnBindPhone',
  data() {
    return {
      title: '温馨提示',
      visible: false,
      content: '',
      leftBtnTxt: '取消',
      rightBtnTxt: '确定',
      showLeft: true,
      showRight: true,
      onClickLeftBtn() {},
      onClickRightBtn() {},
      onClose() {},
      afterClose() {},
      onUnBindSuccess() {},
      onUnBindFail() {},
      timer: null,
      isCountDown: false,
      seconds: 60,
      ruleForm: {
        phoneNumber: '',
        code: ''
      }
    }
  },
  computed: {
    btnTxt() {
      return this.isCountDown ? this.seconds + 'S后获取' : '获取验证码'
    }
  },

    methods: {
       validatePhoneNumber(str) {
        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        return reg.test(str)
      },
      checkPhoneNumber() {
        if (this.ruleForm.phoneNumber.trim().length === 0) {
          Message.error('请输入手机号！')
          return false
        }
        let checkStatus = this.validatePhoneNumber(this.ruleForm.phoneNumber.trim())
        if (!checkStatus) {
          Message.error('请输入正确格式的手机号！')
          return false
        }
        return true
      },
      checkCode() {
         if (this.ruleForm.code.trim().length === 0) {
           Message.error('请输入验证码！')
           return false
         }
         return true
      },
    async getCode() {
      if (!this.isCountDown) {
        // TODO 校验手机号
        let flag = this.checkPhoneNumber()
        if (!flag) {
          return
        }

        this.seconds = 60
        this.isCountDown = true
        this.timer = setInterval(() => {
          if (this.seconds <= 0) {
            clearInterval(this.timer)
            this.seconds = 0
            this.isCountDown = false
            return
          }
          this.seconds--
        }, 1000)

        let res = await getUnbindCode(this.ruleForm.phoneNumber.trim())
        if (res.data.status !== 0) {
          let txt = ''
          switch(res.data.status) {
            case 1011:
              txt = '请更换新的手机号！'
              break;
            default:
              txt = '验证码发送失败！'
              break;
          }
          Message.error(txt)

          return
        }

      }

    },
    hide() {
      this.visible = false
      if (typeof this.afterClose === 'function') {
        this.afterClose(this)
      }
    },
    handleClose() {
      this.hide()
      if (typeof this.onClose === 'function') {
        this.onClose(this)
      }
    },
    async onClickBind() {
      if (this.checkPhoneNumber() && this.checkCode()) {
        let res = await unbindPhoneNumber({
          mobile_phone: this.ruleForm.phoneNumber,
          code: this.ruleForm.code
        })
        console.log(res, '---res')
        switch (res.data.status) {
          case 0:
            this.onUnBindSuccess()
            this.hide()
            break
          default:
            this.onUnBindFail(res)
            break;
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
@import url('./main.less');
</style>
