<template>
  <div>
    <button
      class="purchaseButton"
      @click="openPurchaseModal"
      :style="{ width: width, height: height, borderRadius: borderRadius, fontSize: fontSize }"
    >
      <slot></slot>
    </button>
  </div>
</template>
<script>
import { getToken } from '@/utils/token'
import { mixins } from '@/mixin'

export default {
  name: 'PurchaseButton',
  mixins: [mixins],
  props: {
    width: {
      type: String,
      default: 'auto'
    },
    height: {
      type: String,
      default: 'auto'
    },
    borderRadius: {
      type: String,
      default: '0px'
    },
    fontSize: {
      type: String,
      default: '16px'
    }
  },
  data() {
    return {}
  },
  methods: {
    openPurchaseModal() {
      if (!getToken()) {
        this.$Login()
      } else {
        this.$_mixin_showPurchaseModal()
      }
    }
  },
  created() {}
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
