<template>
  <div class="asideNavWrapper">
    <router-link
      :to="menu.link"
      v-for="(menu, idx) in menus"
      :key="idx"
      :class="{ linkItem: true, active: $route.path === menu.link }"
      :exact="true"
    >
      <img :src="menu.icon" alt="" class="icon" />
      <span class="txt">{{ menu.title }}
        <img src="@/assets/img/ai-icon.svg" alt="" v-if="menu?.isAi" class="ai_icon">
        <img src="@/assets/img/free.svg" alt="" v-if="menu?.isFree" class="free_icon">
      </span>


    </router-link>
  </div>
</template>
<script>
export default {
  name: 'AsideNav',
  components: {},
  props: {
    menus: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods: {},
  created() {}
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
