// 颜色选项
export const COLOR_OPTIONS = [
  {
    color: 'rgba(255,255,255,0)',
    border: true,
    background: `url(${require('@/assets/img/transparent.png')}) no-repeat center/cover`
  },
  {
    color: 'rgba(255,255,255, 1)',
    border: true,
    background: '#fff'
  },

  {
    color: 'rgba(31, 124, 242, 1)',
    border: false,
    background: 'rgba(31, 124, 242, 1)'
  },
  {
    color: 'rgba(31, 74, 242, 1)',
    border: false,
    background: 'rgba(31, 74, 242, 1)'
  },
  {
    color: 'rgba(255, 0, 0, 1)',
    border: false,
    background: 'rgba(255, 0, 0, 1)'
  },
  {
    color: 'rgba(193, 0, 6, 1)',
    border: false,
    background: 'rgba(193, 0, 6, 1)'
  },
  {
    color: 'rgba(183, 179, 179, 1)',
    border: false,
    background: 'rgba(183, 179, 179, 1)'
  }
]
// 电商尺寸列表
export const EC_SIZE_LIST = [
  {
    title: '淘宝',
    width: 800,
    height: 800
  },
  {
    title: '京东',
    width: 500,
    height: 500
  },
  {
    title: '拼多多',
    width: 480,
    height: 480
  },
  {
    title: '唯品会',
    width: 1200,
    height: 1200
  },
  {
    title: '抖音',
    width: 600,
    height: 800
  },
  {
    title: '小红书',
    width: 800,
    height: 800
  },
  {
    title: '阿里巴巴',
    width: 800,
    height: 800
  },
  {
    title: '饿了么',
    width: 480,
    height: 480
  },
  {
    title: '美团',
    width: 600,
    height: 600
  },
  {
    title: '咸鱼',
    width: 800,
    height: 800
  }
]

export const CERTIFICATE_LIST = [
  {
    id: 0,
    tag: '热门',
    values: [
      {
        title: '二寸',
        mmSize: [35, 49],
        pxSize: [413, 579]
      },
      {
        title: '一寸',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '五寸',
        mmSize: [89, 127],
        pxSize: [1051, 1500]
      },
      {
        title: '驾照',
        mmSize: [22, 32],
        pxSize: [259, 377]
      },
      {
        title: '小二寸',
        mmSize: [35, 45],
        pxSize: [413, 531]
      },
      {
        title: '简历照片',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '入学照',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '社保卡',
        mmSize: [26, 32],
        pxSize: [358, 441]
      },
      {
        title: '小一寸',
        mmSize: [22, 32],
        pxSize: [260, 378]
      },
      {
        title: '学籍照片',
        mmSize: [30, 37],
        pxSize: [358, 441]
      }
    ]
  },
  {
    id: 1,
    tag: '常规',
    values: [
      {
        title: '一寸',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '小一寸',
        mmSize: [22, 32],
        pxSize: [260, 378]
      },
      {
        title: '大一寸',
        mmSize: [33, 48],
        pxSize: [390, 567]
      },
      {
        title: '二寸',
        mmSize: [35, 49],
        pxSize: [413, 579]
      },
      {
        title: '小二寸',
        mmSize: [35, 45],
        pxSize: [413, 531]
      },
      {
        title: '大二寸',
        mmSize: [35, 53],
        pxSize: [413, 625]
      },
      {
        title: '三寸',
        mmSize: [54, 84],
        pxSize: [637, 992]
      },
      {
        title: '四寸',
        mmSize: [76, 102],
        pxSize: [897, 1204]
      },
      {
        title: '五寸',
        mmSize: [89, 127],
        pxSize: [1051, 1500]
      }
    ]
  },
  {
    id: 2,
    tag: '证件',
    values: [
      {
        title: '美国签证',
        mmSize: [51, 51],
        pxSize: [602, 602]
      },
      {
        title: '日本签证',
        mmSize: [45, 45],
        pxSize: [531, 531]
      },
      {
        title: '越南签证',
        mmSize: [40, 60],
        pxSize: [472, 708]
      },
      {
        title: '入台证',
        mmSize: [35, 45],
        pxSize: [413, 531]
      },
      {
        title: '电子护照',
        mmSize: [33, 48],
        pxSize: [389, 566]
      },
      {
        title: '海外申请护照在线预约',
        mmSize: [33, 48],
        pxSize: [389, 566]
      },
      {
        title: '冰岛签证',
        mmSize: [40, 50],
        pxSize: [472, 590]
      },
      {
        title: '世界通用签证',
        mmSize: [35, 45],
        pxSize: [413, 531]
      },
      {
        title: '港澳通行证',
        mmSize: [33, 48],
        pxSize: [389, 566]
      },

      {
        title: '韩国签证',
        mmSize: [35, 45],
        pxSize: [413, 531]
      },
      {
        title: '泰国签证',
        mmSize: [35, 45],
        pxSize: [413, 531]
      },
      {
        title: '印度签证',
        mmSize: [51, 51],
        pxSize: [602, 602]
      },
      {
        title: '以色列签证',
        mmSize: [51, 51],
        pxSize: [602, 602]
      },
      {
        title: '签证采集',
        mmSize: [30, 40],
        pxSize: [354, 472]
      },
      {
        title: '马来西亚签证',
        mmSize: [35, 45],
        pxSize: [413, 531]
      },
      {
        title: '新西兰签证',
        mmSize: [76, 102],
        pxSize: [897, 1204]
      },
      {
        title: '意大利签证',
        mmSize: [35, 40],
        pxSize: [413, 472]
      },
      {
        title: '阿根廷签证',
        mmSize: [40, 40],
        pxSize: [472, 472]
      },
      {
        title: '巴西签证',
        mmSize: [40, 50],
        pxSize: [472, 590]
      },
      {
        title: '肯尼亚签证',
        mmSize: [50, 50],
        pxSize: [590, 590]
      },
      {
        title: '入金证',
        mmSize: [35, 45],
        pxSize: [413, 531]
      },
      {
        title: '导游证',
        mmSize: [24, 33],
        pxSize: [285, 385]
      },
      {
        title: '教师资格证',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '入学照',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '国考',
        mmSize: [35, 45],
        pxSize: [413, 531]
      },
      {
        title: '国家司法考试',
        mmSize: [35, 53],
        pxSize: [413, 626]
      },
      {
        title: '二级建造师照片',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '一级建造师证',
        mmSize: [40, 53],
        pxSize: [472, 630]
      },
      {
        title: '二级建造师证',
        mmSize: [39, 56],
        pxSize: [455, 661]
      },
      {
        title: '职业兽医资格证',
        mmSize: [19, 28],
        pxSize: [230, 334]
      },
      {
        title: '自考证件照',
        mmSize: [33, 43],
        pxSize: [384, 512]
      },
      {
        title: '成人自考',
        mmSize: [25, 35],
        pxSize: [294, 413]
      },
      {
        title: '会计从业资格证',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '英语四六级考试',
        mmSize: [33, 48],
        pxSize: [144, 192]
      },
      {
        title: '学位英语',
        mmSize: [33, 48],
        pxSize: [390, 567]
      },
      {
        title: '英语AB级考试',
        mmSize: [33, 48],
        pxSize: [390, 567]
      },
      {
        title: '普通话水平测试',
        mmSize: [33, 48],
        pxSize: [390, 567]
      },
      {
        title: '执业药师考试',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '执业药师资格考试',
        mmSize: [35, 49],
        pxSize: [413, 579]
      },
      {
        title: '执业医师资格报名',
        mmSize: [30, 40],
        pxSize: [354, 472]
      },
      {
        title: '学籍照片',
        mmSize: [30, 37],
        pxSize: [358, 441]
      },
      {
        title: '入学报名',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '大学入学',
        mmSize: [35, 45],
        pxSize: [413, 531]
      },
      {
        title: '高考报名',
        mmSize: [36, 47],
        pxSize: [420, 560]
      },
      {
        title: '全国计算机考试',
        mmSize: [48, 68],
        pxSize: [571, 800]
      },
      {
        title: '护士执业资格考试',
        mmSize: [18, 23],
        pxSize: [210, 276]
      },
      {
        title: '成人自考本科',
        mmSize: [41, 61],
        pxSize: [480, 720]
      },
      {
        title: '自考学位外语考试',
        mmSize: [33, 48],
        pxSize: [390, 567]
      },
      {
        title: '英语三级考试',
        mmSize: [33, 48],
        pxSize: [144, 192]
      },
      {
        title: '西藏二级建造师',
        mmSize: [39, 56],
        pxSize: [455, 661]
      },
      {
        title: '国家公务员考试',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '保险职业认证',
        mmSize: [18, 23],
        pxSize: [210, 270]
      },
      {
        title: '注册会计师',
        mmSize: [15, 19],
        pxSize: [178, 220]
      },
      {
        title: '保险职业证',
        mmSize: [18, 31],
        pxSize: [210, 370]
      },

      {
        title: '居住证',
        mmSize: [26, 32],
        pxSize: [307, 377]
      },
      {
        title: '社保卡',
        mmSize: [26, 32],
        pxSize: [358, 441]
      },
      {
        title: '驾照',
        mmSize: [22, 32],
        pxSize: [259, 377]
      },
      {
        title: '简历照片',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '医保证',
        mmSize: [26, 32],
        pxSize: [358, 441]
      },
      {
        title: '健康证',
        mmSize: [25, 35],
        pxSize: [295, 413]
      },
      {
        title: '执法证',
        mmSize: [22, 30],
        pxSize: [265, 351]
      },
      {
        title: '校园卡',
        mmSize: [20, 27],
        pxSize: [240, 320]
      }
    ]
  }
]
