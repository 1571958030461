<template>
  <div class="fileCardWrapper">
    <div class="cardLeft">
      <img :src="fileInfo.origin?.src || defaultIcon" alt="" class="cardImg" />
      <div class="fileName">
        {{ fileInfo.origin?.filename }}
      </div>
    </div>
    <div class="cardRight">
      <div v-if="!isLoading" class="fileInfo">
        <div class="cardCorner" @click="handleRemove">
          <span class="icon"></span>
        </div>
        <div class="infoBox">
          <p class="txt">原图</p>
          <p class="info">
            <span>{{ fileInfo.origin?.suffix }}</span>
            <span>{{ fileInfo.origin?.width }}x{{ fileInfo.origin?.height }}</span>
            <span>{{ fileInfo.origin?.size }}kb</span>
          </p>
        </div>
        <div class="infoBox">
          <p class="txt">压缩后</p>
          <p class="info">
            <span>{{ fileInfo.target?.suffix }}</span>
            <span>{{ fileInfo.target?.width }}x{{ fileInfo.target?.height }}</span>
            <span>{{ fileInfo.target?.size }}kb</span>
          </p>
        </div>
        <div class="btnGroups">
          <button class="btn" @click="handlePreview">预览</button>
          <button class="btn" @click="downloadImage">下载</button>
        </div>
      </div>
      <div v-else class="progressWrapper">
        <div class="progressBox">
          <div class="progress"></div>
        </div>
        <p>处理中...</p>
      </div>
    </div>
  </div>
</template>
<script>
import getImageFileInfo from '@/utils/getImageFileInfo'
import {compressAvif, compressImage, compressPng, compressPngSpeed, compressSvg, outputTheSpecifiedSize} from '@/core'

export default {
  name: 'FileCard',
  components: {},
  props: {
    file: {
      validator: function (value) {
        return value.raw instanceof Blob
      }
    }
  },
  data() {
    return {
      isLoading: true,
      defaultIcon: require('@/assets/img/default_icon.svg'),
      fileInfo: {
        origin: {
          src: require('@/assets/img/default_icon.svg') // 给一张默认图
        },
        target: {}
      }
    }
  },

  watch: {
    file: {
      handler(val) {
        this.executeCompress(val)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handlePreview() {
      this.$emit('onPreview', this.fileInfo)
    },
    // 移除当前图片
    handleRemove() {
      this.$emit('remove', this.fileInfo)
    },
    // 下载当前压缩图片
    downloadImage() {
      this.$emit('download', this.fileInfo, this.file?.options)
    },
    /**
     * @description 执行压缩
     * @param fileParams
     * @returns {Promise<void>}
     */
    async executeCompress(fileParams) {
      this.isLoading = true
      let compressBlob = fileParams.raw
      let originInfo = await getImageFileInfo(compressBlob)
      this.fileInfo.origin = originInfo

      try {
        if (compressBlob.type !== 'image/gif') {
          if (fileParams.options.maxSize !== Number.POSITIVE_INFINITY && fileParams.options.maxSize > 0) {
            // 用户指定压缩大小
            compressBlob = await outputTheSpecifiedSize(fileParams.raw, {
              maxSize: fileParams.options.maxSize * 0.95
            })
          } else {
            if (this.$route.name === 'compress-png') {
              if (fileParams.options.compressPngMode === 2) {
                compressBlob = await compressPngSpeed(fileParams.raw)
              } else {
                compressBlob = await compressPng(fileParams.raw, {
                  colors: fileParams.options.colors,
                  dithering: fileParams.options.dithering,
                })
              }

            } else if (this.$route.name === 'compress-svg') {
              compressBlob = await compressSvg(fileParams.raw)
            } else if (this.$route.name === 'compress-avif') {
              compressBlob = await compressAvif(fileParams.raw, {
                avifQuality: fileParams.options?.avifQuality,
                avifSpeed: fileParams.options?.avifSpeed
              })
            } else {
              compressBlob = await compressImage(fileParams.raw, {
                quality: fileParams.options.quality
              })
            }

          }
          let compressInfo = await getImageFileInfo(compressBlob)
          this.fileInfo.target = compressInfo
        } else {
          /**
           * 当遇到gif图片时默认给一个假的数据
           */
          if (fileParams.options.maxSize !== Number.POSITIVE_INFINITY && fileParams.options.maxSize > 0) {
            this.fileInfo.target = {
              ...originInfo,
              size: (fileParams.options.maxSize * 0.8).toFixed(1)
            }
          } else {
            this.fileInfo.target = {
              ...originInfo,
              size:
                fileParams.options.quality >= 1
                  ? originInfo.raw.size
                  : ((originInfo.raw.size * fileParams.options.quality) / 1024).toFixed(1)
            }
          }
        }
      } catch (e) {
        // TODO 压缩方法执行失败
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
