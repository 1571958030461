import Vue from 'vue'
import Main from './main.vue'

let BindOrderConstructor = Vue.extend(Main)

const BindOrder = (options) => {
  options = options || {}
  let data = Object.assign({}, options, {
    afterClose: (vm) => {
      if (typeof options?.afterClose === 'function') {
        options.afterClose()
      }
      // vm.$destroy()
    }
  })
  let instance = new BindOrderConstructor({
      data: data,
  })
  instance.$mount()
  document.body.appendChild(instance.$el)
  instance.visible = true;
  return instance
}

export default BindOrder