<template>
  <el-dialog
    class="previewDialogBox"
    :visible.sync="dialogVisible"
    width="860px"
    :append-to-body="true"
    :show-close="false"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    :custom-class="'previewDialogContainer'"
    :destroy-on-close="true"
  >
    <div class="title">
      <div>
        <span class="title-h3">预览</span>
        <span class="title-desc"
          >左右滑动可查看{{
            $route.path === '/' || $route.path.indexOf('/compress') != -1 ? '压缩' : '处理'
          }}前后效果对比图</span
        >
      </div>
      <i class="el-icon-close closeIcon" @click="handleClose"></i>
    </div>
    <div
      id="cover"
      onpaste="return false"
      oncontextmenu="return false"
      oncopy="return false"
      oncut="return false"
      class="cover"
    >
      <div class="cover-main compress">
        <span class="cd-image-label" style="left: 16px"
          >{{ $route.path === '/' || $route.path.indexOf('/compress') != -1 ? '压缩' : '处理' }}前
          {{ currentFileInfo?.origin?.size }}KB</span
        >
        <span class="cd-image-label" style="right: 16px"
          >{{ $route.path === '/' || $route.path.indexOf('/compress') != -1 ? '压缩' : '处理' }}后
          {{ currentFileInfo?.target?.size }}KB</span
        >
        <div
          :class="{
            imageContainer: true,
            scaleHeight: currentFileInfo?.origin?.width >= currentFileInfo?.origin?.height
          }"
        >
          <div class="figureBox" :style="{ width: position + 'px' }">
            <div class="imgBox">
              <img :draggable="false" :src="currentFileInfo?.origin?.src" alt="" class="nowImg" />
            </div>
          </div>
          <img
            :draggable="false"
            :src="currentFileInfo?.target?.src"
            alt=""
            class="originImg"
            :style="{
              width: currentFileInfo?.target?.width,
              height: currentFileInfo?.target?.height
            }"
          />
          <div class="moveBtn" :style="{ left: position + 'px' }">
            <div class="move" @mousedown="figureMouseDown"></div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'PreviewModal',
  components: {},
  props: {
    fileInfo: Object,
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dragging: false,
      position: `calc(50% - 20px)`,
      currentFileInfo: {}
    }
  },
  watch: {
    fileInfo: {
      handler(val) {
        this.currentFileInfo = val
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    beforeWidth() {
      return this.position
    }
  },
  methods: {
    handleClose() {
      this.dragging = false
      this.position = `calc(50% - 20px`
      this.$emit('onClose')
    },
    figureMouseDown(event) {
      this.dragging = true
      const clientX = event.clientX || event.touches[0].clientX

      const container = this.$el.querySelector('.imageContainer')
      const containerLeft = container.getBoundingClientRect().left
      const figureBox = this.$el.querySelector('.move')
      const figureBoxWidth = figureBox.clientWidth + 2 // 2是因为 border宽度

      const newPosition = clientX - containerLeft

      this.position = Math.max(figureBoxWidth / 2, Math.min(container.offsetWidth - figureBoxWidth / 2, newPosition))

      document.addEventListener('mousemove', this.drag)
      document.addEventListener('touchmove', this.drag, { passive: false })
      document.addEventListener('mouseup', this.stopDrag)
      document.addEventListener('touchend', this.stopDrag)
    },
    drag(event) {
      if (this.dragging) {
        const clientX = event.clientX || event.touches[0].clientX
        const container = this.$el.querySelector('.imageContainer')
        const containerLeft = container.getBoundingClientRect().left
        const figureBox = this.$el.querySelector('.move')
        const figureBoxWidth = figureBox.clientWidth + 2
        const newPosition = clientX - containerLeft
        this.position = Math.max(figureBoxWidth / 2, Math.min(container.offsetWidth - figureBoxWidth / 2, newPosition))
      }
    },
    stopDrag() {
      this.dragging = false
      document.removeEventListener('mousemove', this.drag)
      document.removeEventListener('touchmove', this.drag)
      document.removeEventListener('mouseup', this.stopDrag)
      document.removeEventListener('touchend', this.stopDrag)
    }
  },
  created() {}
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
