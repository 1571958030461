<template>
  <div class="stepSwiperWrapper">
    <div class="indicatorPositionWrapper">
      <div class="indicatorPositionList">
        <div v-for="(item, idx) in steps" :key="idx" class="indicatorPositionItem">
          <div :class="{ active: currentIndex === idx, stepTxt: true }">第{{ idx + 1 }}步</div>
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.content }}</div>
        </div>
      </div>
    </div>

    <div class="carouselWrapper">
      <el-carousel height="388px" indicator-position="none" arrow="never" @change="onChange">
        <el-carousel-item v-for="(item, idx) in steps" class="carouselItem" :key="idx">
          <img :src="item.src" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepSwiper',
  props: {
    steps: Array
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  methods: {
    onChange(index) {
      this.currentIndex = index
    }
  }
}
</script>

<style scoped lang="less">
@import url('./index.less');
</style>
