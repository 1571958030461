/* 用于验证码的字符列表 */
const nums = new Array(10).fill(0).map((val, idx) => idx)
const letters = new Array(26).fill(0).map((val, idx) => String.fromCharCode('a'.charCodeAt() + idx))

function GVerify({ el, size = 4, type = 'blend' }) {
  this.options = {
    el: document.querySelector(el), // el 保存容器的 html 元素
    size, // 验证码长度，默认为 4
    type, // 验证码类型，可选值有：blend 混合、number 纯数字、letter 纯字符；默认为混合类型
    canvasId: `_canvas_${el}`, // 画布 id
    code: '', // 实际验证码
    txts: type === 'number' ? nums : type === 'letter' ? letters : [...nums, ...letters] // 验证码候选字符
  }
  if (!this.options.el) throw new Error('container not found')
  this.init() // 初始化
  this.refresh() // 首次加载时刷新
}

/* 初始化 */
GVerify.prototype.init = function () {
  /* 初始化 canvas 画布 */
  const container = this.options.el
  const canvas = document.createElement('canvas')
  canvas.id = this.options.canvasId // 设置 id
  canvas.width = container.offsetWidth // 设置宽度与容器同宽
  canvas.height = container.offsetHeight // 设置高度与容器同高
  canvas.style.cursor = 'pointer'
  canvas.innerHTML = '您的的浏览器不支持 canvas'
  container.appendChild(canvas)
  const self = this
  // 可点击刷新
  canvas.onclick = function () {
    self.refresh()
  }
}

/* 刷新 */
GVerify.prototype.refresh = function () {
  // 先获取画布元素并设置好相关参数
  const canvas = document.getElementById(this.options.canvasId)
  const { width, height } = canvas
  const { size, txts } = this.options
  if (!canvas) return

  // 获取画布上下文并初始化背景
  const ctx = canvas.getContext('2d')
  ctx.textBaseline = 'middle'
  ctx.fillStyle = randomColor(180, 240)
  ctx.fillRect(0, 0, width, height) // 随机背景色

  /* 绘制验证码 */
  this.options.code = ''
  for (let i = 0; i < size; i++) {
    const c = txts[randomInt(0, txts.length)] // 随机生成字符
    this.options.code += c
    // 字符样式设置
    ctx.font = `${randomInt(height / 2, height)}px Simhei` // 随机大小
    ctx.fillStyle = randomColor(50, 160) // 随机颜色
    ctx.shadowOffsetX = randomInt(-3, 3) // 随机阴影偏移
    ctx.shadowOffsetY = randomInt(-3, 3)
    ctx.shadowBlur = randomInt(-3, 3) // 随机阴影模糊
    ctx.shadowColor = 'rgba(0, 0, 0, 0.3)' // 黑色半透明阴影
    const x = randomInt((width / size) * i, (width / size) * (i + 0.8))
    const y = height / 2
    const degree = randomInt(-30, 30)
    /* 改变坐标系 */
    ctx.translate(x, y) // 原点
    ctx.rotate((degree * Math.PI) / 180) // 旋转
    /* 填上数字 */
    ctx.fillText(c, 0, 0)
    /* 恢复坐标系 */
    ctx.rotate((-degree * Math.PI) / 180)
    ctx.translate(-x, -y)
  }
  /* 绘制干扰线 */
  const lineCount = randomInt(4, 8)
  for (let i = 0; i < lineCount; i++) {
    ctx.strokeStyle = randomColor(40, 180)
    ctx.beginPath()
    // 随机起点和终点
    ctx.moveTo(randomInt(0, width), randomInt(0, height))
    ctx.lineTo(randomInt(0, width), randomInt(0, height))
    ctx.stroke()
  }

  /* 绘制干扰点 */
  const pointCount = randomInt(width / 4, width / 2)
  for (let i = 0; i < pointCount; i++) {
    ctx.fillStyle = randomColor(0, 255)
    ctx.beginPath()
    // 随机原点
    ctx.arc(randomInt(0, width), randomInt(0, height), 1, 0, 2 * Math.PI)
    ctx.fill()
  }
}

/* 验证 */
GVerify.prototype.validate = function (code) {
  // 直接校验字符串
  return code === this.options.code
}

/* 生成随机整数 */
function randomInt(from = 0, to = 1) {
  return Math.floor(from + Math.random() * (to - from))
}

/* 生成随机颜色 */
function randomColor(min = 0, max = 255) {
  const [r, g, b] = [randomInt(min, max), randomInt(min, max), randomInt(min, max)]
  return `rgb(${r},${g},${b})`
}

export default GVerify
