var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"previewDialogBox",attrs:{"visible":_vm.dialogVisible,"width":"860px","append-to-body":true,"show-close":false,"modal-append-to-body":false,"close-on-click-modal":false,"close-on-press-escape":false,"lock-scroll":false,"custom-class":'previewDialogContainer',"destroy-on-close":true},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"title"},[_c('div',[_c('span',{staticClass:"title-h3"},[_vm._v("预览")]),_c('span',{staticClass:"title-desc"},[_vm._v("左右滑动可查看"+_vm._s(_vm.$route.path === '/' || _vm.$route.path.indexOf('/compress') != -1 ? '压缩' : '处理')+"前后效果对比图")])]),_c('i',{staticClass:"el-icon-close closeIcon",on:{"click":_vm.handleClose}})]),_c('div',{staticClass:"cover",attrs:{"id":"cover","onpaste":"return false","oncontextmenu":"return false","oncopy":"return false","oncut":"return false"}},[_c('div',{staticClass:"cover-main compress"},[_c('span',{staticClass:"cd-image-label",staticStyle:{"left":"16px"}},[_vm._v(_vm._s(_vm.$route.path === '/' || _vm.$route.path.indexOf('/compress') != -1 ? '压缩' : '处理')+"前 "+_vm._s(_vm.currentFileInfo?.origin?.size)+"KB")]),_c('span',{staticClass:"cd-image-label",staticStyle:{"right":"16px"}},[_vm._v(_vm._s(_vm.$route.path === '/' || _vm.$route.path.indexOf('/compress') != -1 ? '压缩' : '处理')+"后 "+_vm._s(_vm.currentFileInfo?.target?.size)+"KB")]),_c('div',{class:{
          imageContainer: true,
          scaleHeight: _vm.currentFileInfo?.origin?.width >= _vm.currentFileInfo?.origin?.height
        }},[_c('div',{staticClass:"figureBox",style:({ width: _vm.position + 'px' })},[_c('div',{staticClass:"imgBox"},[_c('img',{staticClass:"nowImg",attrs:{"draggable":false,"src":_vm.currentFileInfo?.origin?.src,"alt":""}})])]),_c('img',{staticClass:"originImg",style:({
            width: _vm.currentFileInfo?.target?.width,
            height: _vm.currentFileInfo?.target?.height
          }),attrs:{"draggable":false,"src":_vm.currentFileInfo?.target?.src,"alt":""}}),_c('div',{staticClass:"moveBtn",style:({ left: _vm.position + 'px' })},[_c('div',{staticClass:"move",on:{"mousedown":_vm.figureMouseDown}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }