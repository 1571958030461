<template>
  <el-dialog
    class="purchaseBox"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="900px"
    :append-to-body="true"
    :show-close="false"
    :custom-class="'purchaseWrapper'"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    @open="onOpenDialog"
    @closed="handleClose"
    :destroy-on-close="true"
  >
    <div class="purchaseModalMain">
      <div class="mainHeader">
        <div class="userInfo">
          <img src="./assets/img/user_avatar.png" alt="" class="avatar" />
          <div class="userInfoRight">
            <div class="userId">ID: {{ userInfo?.uid }}</div>
            <div class="tag" v-if="!isVip">普通用户</div>
            <div class="vipTag" v-else-if="isTimeVIP || isCfVIP">VIP会员</div>
          </div>
        </div>
        <i class="closeIcon el-icon-close" @click="handleCancel"></i>
      </div>
      <div class="purchaseModalContainer">
        <div class="modalContainerLeft">
          <div class="titleBox">
            <div class="title"><span>会员专属权益</span></div>
            <div class="subtitle">覆盖全站所有功能</div>
          </div>
          <ul class="entitlementTable">
            <li>
              <span class="icon"></span>
              批量压缩文件
            </li>
            <li>
              <span class="icon"></span>
              不限次数使用
            </li>
            <li>
              <span class="icon"></span>
              自由设置图片参数
            </li>
            <li>
              <span class="icon"></span>
              支持5+格式图片压缩
            </li>
            <li>
              <span class="icon"></span>
              支持10+格式图片转换
            </li>
            <li>
              <span class="icon"></span>
              支持PDF格式转换
            </li>
            <li>
              <span class="icon"></span>
              专属人工服务
            </li>
            <li>
              <span class="icon"></span>
              新增功能免费使用
            </li>
            <li>
              <span class="icon"></span>
              提供正规发票
            </li>
          </ul>
        </div>
        <div class="modalContainerRight">
          <div class="packageList">
            <div
              class="packageItem"
              @click="checkPurchase(item.id)"
              :class="{ packageItem: true, active: checkID === item.id }"
              v-for="item in packages"
              :key="item.id"
            >
              <div class="corner" v-if="item.corner">{{ item.corner }}</div>
              <div class="packageItemContainer">
                <div class="packageTitle">{{ item.title }}</div>
                <div class="priceBox">
                  <div class="currentPrice">
                    ￥<span class="price">{{ item.unitPrice }}</span
                  ><span class="unit" v-if="item.unit">{{ item.unit }}</span>
                  </div>
                  <div class="originPrice" v-if="item.originPrice">原价￥{{ item.originPrice }}</div>
                </div>
                <div class="packageDesc">{{ item.description }}</div>
              </div>
            </div>
          </div>

          <div class="payInfoBox">
            <div class="qrCodeBox">
              <img :src="qrCodeSrc" alt="" class="qrCodeImg" v-if="!loading" />
              <div class="loading" v-else>
                <img src="@/assets/img/loading.png" alt="" class="loadingImg" />
              </div>
            </div>
            <div class="payInfo">
              <div class="info">
                <span>应付金额：</span>
                <span class="price">￥{{ price }}</span>
                <div class="discounts" v-if="discountPrice">{{ discountPrice }}</div>
                <div class="payInfoTips">购买后可联系客服开具发票</div>
              </div>
              <div class="channelTips">
                <span class="wxPay"></span>
                <span class="aliPay"></span>
                <span>请使用微信/支付宝扫码支付</span>
              </div>
              <div class="payUserInfo">
                <ul class="payUserList" style="height: 48px">
                  <li>用户139****7468 12分钟前购买了 终身会员</li>
                  <li>用户136****8012 10分钟前购买了 终身会员</li>
                  <li>用户156****9735 12分钟前购买了 终身会员</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tipsBox">
            <div class="tipsTitle">购买须知</div>
            <p class="tipsItem"><span class="blue">没有自动续费</span>，请放心购买</p>
            <p class="tipsItem">按张付费购买下载次数 <span class="blue">永久有效</span></p>
            <p class="tipsItem">开通任一会员，会员有效期内所有功能 <span class="blue">不限使用、不限张数</span></p>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import QRCode from 'qrcode'
import { createOrder, getPayStatus } from '@/api'
import { Message } from 'element-ui'
import { trackOrder } from '@/utils/track'
import { packageList, sougou_packageList } from '@/config'

export default {
  name: 'PurchaseModal',
  components: {},
  data() {
    return {
      loading: false,
      checkIndex: 0,
      purchaseList: [],
      qrCodeSrc: '',
      pollCount: 0, // 轮询次数
      payResultTimer: null,
      checkID: 0,
      price: 0,
      discountPrice: 0,
      visible: false,
      onCancel: () => {},
      afterClose: () => {},
      onPaySuccess: () => {},
      packageList: packageList,
      souGouPackageList: sougou_packageList,
      startTime: new Date().getTime(),
      expiredTime: 1 * 60 * 1000 // 过期时间，默认1分钟
    }
  },
  computed: {
    ...mapState({
      allCert: state => state.allCert
    }),
    ...mapGetters(['getUserInfo', 'isTimeVIP', 'isCountVIP', 'isVip', 'isCfVIP', 'isThreeDayVIP']),

    userInfo() {
      return this.getUserInfo
    },
    packages() {
      let data = this.packageList
      if (this.souGouPackageList?.length) {
        let inSougou = !!localStorage.getItem('sougou_tag')
        if (inSougou) {
          data = this.souGouPackageList
        }

      }
      // 筛选出符合当前用户等级的套餐
      return data.filter(item => item.level.includes(this.allCert?.vip))
    }
  },
  methods: {
    ...mapActions(['updateAllCert']),
    /**
     * @description 转换二维码
     * @param path 路径信息
     * @returns {Promise<unknown>}
     */
    async toQRCode(path) {
      return QRCode.toDataURL(path)
    },
    /**
     * @description 轮询，获取支付状态
     * @param orderId 订单ID
     */
    lookup(orderId) {
      clearTimeout(this.payResultTimer)

      // if (this.pollCount > 5000) {
      //   // 支付超时
      //   this.hide()
      //   return
      // }
      // 判断二维码是否过期
      if (new Date().getTime() - this.startTime >= this.expiredTime) {
        this.checkPurchase(this.checkID)
        return
      }

      this.payResultTimer = setTimeout(async () => {
        let r = await getPayStatus(orderId)
        if (r.data.status === 0 && r.data.data.order.status === 1) {
          // 查询成功 并且 状态为1 或者支付超时
          await this.$store.dispatch('updateUserInfo')
          Message({
            type: 'success',
            message: '支付成功'
          })
          trackOrder(this.checkID, orderId, r.data.data.money.cash_total)
          this.onPaySuccess()
          this.hide()
        } else {
          this.lookup(orderId)
        }
      }, 1500)

      this.pollCount++ // 增加轮询次数
    },
    /**
     * @description 切换套餐
     * @param idx 选中的索引
     * @returns {Promise<void>}
     */
    async checkPurchase(id) {
      this.loading = true
      this.checkID = id
      let target = this.packages.find(item => item.id === id)
      this.price = target?.price
      this.discountPrice = target?.discountPrice
      let res = await createOrder(id)
      this.startTime = new Date().getTime()
      if (res.data.status === 0) {
        let src = await this.toQRCode(res.data.data)
        this.qrCodeSrc = src
        this.loading = false
        this.pollCount = 0 // 清空轮询次数
        let s = res.data.data.split('/')
        let orderId = s[s.length - 1]
        this.lookup(orderId)
      } else {
        // 二维码获取失败
      }
    },
    /**
     * 取消
     */
    handleCancel() {
      this.onCancel()
      this.hide()
    },
    /**
     * @description 关闭窗口
     */
    handleClose() {
      this.afterClose()
    },
    hide() {
      clearInterval(this.payResultTimer)
      this.visible = false
    },

    /**
     * @description 打开窗口
     */
    async onOpenDialog() {
      // 默认选择当前用户等级推荐的
      let target = this.packages.find(item => item.defaultChecked)
      target = target ? target : this.packages[0]
      await this.checkPurchase(target?.id)
    }
  }
}
</script>
<style scoped lang="less">
@import './main.less';
</style>
