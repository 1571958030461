import authAxios from "@/axios/authAxios";

/**
 * 获取下载token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getDownloadToken() {
  return authAxios.post('/imageformat/convertimage')
}

/**
 * 检查下载token
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function checkUserImage(token) {return authAxios.post('imageformatV2/checkuserimage', {
    dl_token: token
  })
}