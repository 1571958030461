import authAxios from '@/axios/authAxios'

export function uploadFile() {}

export function getOSSToken() {
  return authAxios.post('/imageformat/getosstoken')
}

/**
 * 抠图
 * @param source oss地址
 * @returns {Promise<AxiosResponse<any>>}
 */
export function imgCut(source) {
  return authAxios.post('/imageformat/cutout', {
    file_addr: source
  })
}
