import { v4 as uuidV4 } from 'uuid'
import isEmpty from 'lodash-es/isEmpty'
import { getExtByMimeType, getMimeTypeByExt } from '@/utils'
import { duce, fileFormatConversion, fileToPdfFn, METHODS, pdfCompressFn, pdfToImgFn } from '@/api'
import { getFileConvertStatus, uploadOSS } from '@/utils/uploadOSS'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { Message } from 'element-ui'
// 转换状态
export const CONVERT_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail'
}

const BUCKET = 'pdfgeshi'

let initState = {
  inputFile: {},
  outputFile: {},
  downloadHistory: {},
  downloadStatus: false, // 是否下载成功
  isDownloading: false, // 是否下载中
  convertStatus: CONVERT_STATUS.PENDING // 转换成功
}
export default {
  namespaced: true,
  state: () => initState,
  mutations: {
    updateDownloadStatus: (state, status) => {
      state.downloadStatus = status
    },
    updateIsDownloading: (state, status) => {
      state.isDownloading = status
    },
    updateInputFile: (state, fileInfo) => {
      state.inputFile = fileInfo
    },
    updateOutputFile: (state, fileInfo) => {
      state.outputFile = fileInfo
    },
    updateConvertStatus: (state, status) => {
      state.convertStatus = status
    },
    updateDownloadHistory: (state, fileInfo) => {
      state.downloadHistory = fileInfo
    },
    resetStore: state => {
      state.convertStatus = CONVERT_STATUS.PENDING
      state.inputFile = {}
      state.outputFile = {}
      state.downloadHistory = {}
      state.downloadStatus = false // 是否下载成功
      state.isDownloading = false // 是否下载中
      state.convertStatus = CONVERT_STATUS.PENDING // 转换成功
    }
  },
  actions: {
    resetStore: ({ commit }) => {
      commit('resetStore')
    },
    setInputFile: ({ commit }, fileInfo) => {
      commit('updateInputFile', { ...fileInfo, fid: uuidV4() })
    },

    changeTransitionState: ({ commit, state }, options = {}) => {
      const type = getMimeTypeByExt(options.to)
      commit('updateOutputFile', {
        ...state.inputFile,
        name: `${state.inputFile.namePrefix}.${options.to}`,
        type,
        ext: options.to
      })
      commit('updateConvertStatus', CONVERT_STATUS.SUCCESS)
    },
    // 文件转换
    fileConversion: async ({ commit, state, rootGetters, dispatch }, options = {}) => {
      try {
        commit('updateIsDownloading', true)
        // 上传文件
        let uploadResult = await uploadOSS(
          `${uuidV4()}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}_${state.inputFile.name}`,
          state.inputFile.raw,
          BUCKET
        )
        const source = uploadResult.url
        commit('updateInputFile', { ...state.inputFile, source })
        // 拿到文件地址，转换文件
        let res = {}
        if (options.method === METHODS.FILE_FORMAT_CONVERSION) {
          res = await fileFormatConversion(uploadResult?.url, options.to)
        } else if (options.method === METHODS.PDF_COMPRESS) {
          res = await pdfCompressFn(source, options.quality)
        } else if (options.method === METHODS.PDF_TO_IMG) {
          res = await pdfToImgFn(source, options.toStyle, 'pdf')
        } else if (options.method === METHODS.FILE_TO_PDF) {
          if (!state.inputFile.type) {
            // 兼容CAD文件
            let txtArr = state.inputFile.raw.name.split('.')
            let ext = txtArr[txtArr.length - 1]
            res = await fileToPdfFn(source, ext)
          } else {
            res = await fileToPdfFn(source, getExtByMimeType(state.inputFile.type))
          }
        }

        if (res.data?.data.code === 10000) {
          const token = res.data.data.result.token
          // 获取文件转换状态
          let { source } = await getFileConvertStatus(token)
          const zip = new JSZip()
          const fetchPromises = []

          if (Array.isArray(source)) {
            // 如果是个数组
            source.forEach((imgUrl, idx) => {
              let promise = fetch(imgUrl)
                .then(response => response.blob())
                .then(blob => {
                  saveAs(blob, `${state.outputFile.namePrefix}_${idx}.${state.outputFile.ext}`)
                })
            })
          } else {
            await fetch(source)
              .then(response => response.blob())
              .then(async blob => {
                saveAs(blob, state.outputFile.name)
              })
          }

          let downloadCounts = 1 // 默认下载次数

          let r = await duce(downloadCounts)
          if (r.data.status === 0) {
            await dispatch('updateAllCert', r.data.data, { root: true })
            // 扣除用户下载次数，然后将此次数据添加到下载历史
            commit('updateDownloadHistory', { ...state.outputFile, source: source })
          } else {
            Message.error('下载失败！')
          }
          commit('updateDownloadStatus', true)
        } else {
          commit('updateDownloadStatus', false)
          Message.error('文件转换失败，请检查文件！')
        }
      } catch (e) {
        commit('updateDownloadStatus', false)
        Message.error('文件转换失败，请检查文件！')
      } finally {
        commit('updateIsDownloading', false)
      }
    },
    // 下载
    async downloadFile({ state, dispatch, commit, rootState, rootGetters }, options) {
      // 判断是否有可以下载的图片
      if (state.convertStatus === CONVERT_STATUS.FAIL || isEmpty(state.outputFile)) {
        return
      }

      if (!isEmpty(state.downloadHistory)) {
        const zip = new JSZip()
        const fetchPromises = []
        // 下载文件
        if (Array.isArray(state.downloadHistory?.source)) {
          state.downloadHistory?.source.forEach((imgUrl, idx) => {
            let promise = fetch(imgUrl)
              .then(response => response.blob())
              .then(blob => {
                saveAs(blob, `${state.outputFile.namePrefix}_idx.${state.outputFile.ext}`)
              })
            fetchPromises.push(promise)
          })
          await Promise.all(fetchPromises) // 等待所有远程文件下载完成
        } else if (typeof state.downloadHistory?.source === 'string') {
          await fetch(state.downloadHistory?.source)
            .then(response => response.blob())
            .then(async blob => {
              saveAs(blob, state.outputFile.name)
            })
        }
        commit('updateDownloadStatus', true)
        commit('updateDownloadHistory', { ...state.outputFile, source: state.downloadHistory.source })
      } else {
        // 执行转换
        dispatch('fileConversion', options)
      }
    }
  }
}
