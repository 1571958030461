<template>
  <div class="defaultLayoutWrapper">
    <Header />
    <div class="views">
      <slot></slot>
    </div>
    <div class="areaWrapper featuresWrapper">
      <div class="areaContainer">
        <div class="areaTitle">{{ featuresAreaTitle }}</div>
        <div class="featuresList">
          <div v-for="(feature, idx) in features" :key="idx" class="featureItem">
            <img :src="feature.icon" alt="" class="icon" />
            <div class="featureTitle">{{ feature.title }}</div>
            <div class="featureContent">{{ feature.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="areaWrapper" v-if="path != '/edit/upcolor' && path != '/edit/laozhaopian' && path != '/edit/id'">
      <div class="areaContainer">
        <div class="areaTitle">{{ stepsAreaTitle }}</div>
        <StepSwiper :steps="steps" />
<!--        <div class="freeTrialContainer">-->
<!--          <router-link to="/" class="freeTrialLink">-->
<!--            <span class="txt">免费体验</span>-->
<!--            <span class="icon"></span>-->
<!--          </router-link>-->
<!--        </div>-->
      </div>
    </div>
    <div class="AI" v-else-if="path != '/edit/id'">
      <div class="section1" style="margin-top:60px;">
        <div class="w_1200 wrap">
          <h3>AI智能图像技术</h3>
          <div class="content">
            <div class="l" style="box-shadow:none;">
              <div class="t">
                <p>
                  <span>老照片修复</span>
                </p>
                <p class="p_16">
                  人工智能深度学习技术，将模糊人脸细节进行高清修复。无论是发黄的老照片，旧设备拍摄的陈年旧照，都能一键变清晰，找回儿时的记忆。
                </p>
              </div>
              <div class="img-content">
                <div id="bal-repair" class="bal-container">
                  <div class="bal-after">
                    <img
                            src="https://res.yunkun.cn/pic_geshicn/old_photographs_1_1.jpg"
                            draggable="false"
                            class="picture_after"
                    />
                  </div>
                  <div class="bal-before">
                    <div class="bal-before-inset">
                      <img
                              src="https://res.yunkun.cn/pic_geshicn/old_photographs_1.jpg"
                              draggable="false"
                              class="picture_before"
                      />
                    </div>
                  </div>
                  <div class="bal-handle">
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="r"  >
              <div class="item"  style="box-shadow:none;">
                <div class="t"  >
                  <p>
                    <span>黑白照片上色</span>
                  </p>
                  <p class="p_16">
                    智能AI着色算法，大量数据深度学习，轻松为黑白照片还原更真实自然的颜色。旧照片重新上色，将过去带回你的眼前，使回忆变得鲜活。
                  </p>
                </div>
                <div class="img-content">
                  <div id="best-quality" class="bal-container">
                    <div class="bal-after">
                      <img
                              src="https://res.yunkun.cn/pic_geshicn/old_photographs_2_1.jpg"
                              draggable="false"
                              class="picture_after"
                      />
                    </div>
                    <div class="bal-before">
                      <div class="bal-before-inset">
                        <img
                                src="https://res.yunkun.cn/pic_geshicn/old_photographs_2.jpg"
                                draggable="false"
                                class="picture_before"
                        />
                      </div>
                    </div>
                    <div class="bal-handle">
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item"  style="box-shadow:none;">
                <div class="t">
                  <p>
                    <span>模糊人像修复</span>
                  </p>
                  <p class="p_16">
                    自动处理图片噪点和锯齿部分，轻松还原真实高清的照片。除了人像智能修复，还支持一键无损放大图片尺寸，高清放大局部细节。
                  </p>
                </div>
                <div class="img-content">
                  <div id="repair" class="bal-container">
                    <div class="bal-after">
                      <img
                              src="https://res.yunkun.cn/pic_geshicn/old_photographs_3_1.jpg"
                              draggable="false"
                              class="picture_after"
                      />
                    </div>
                    <div class="bal-before">
                      <div class="bal-before-inset">
                        <img
                                src="https://res.yunkun.cn/pic_geshicn/old_photographs_3.jpg"
                                draggable="false"
                                class="picture_before"
                        />
                      </div>
                    </div>
                    <div class="bal-handle">
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="photoEditorPage" v-else>
      <h3>多尺寸多底色证件照处理</h3>
      <div class="carouselBox">
        <img src="https://res.yunkun.cn/miaokou/img/slice_1.png" alt="" style="width: 1200px;"/>
<!--        <el-carousel-->
<!--                ref="swiper"-->
<!--                class="ttt"-->
<!--                :autoplay="true"-->
<!--                indicator-position="none"-->
<!--                arrow="never"-->
<!--                @change="onSwiperChange"-->
<!--        >-->
<!--          <el-carousel-item class="carouselItem">-->
<!--            <img src="https://res.yunkun.cn/miaokou/img/slice_1.png" alt="" />-->
<!--          </el-carousel-item>-->
<!--          <el-carousel-item class="carouselItem">-->
<!--            <img src="https://res.yunkun.cn/miaokou/img/slice_2.png" alt="" />-->
<!--          </el-carousel-item>-->
<!--          <el-carousel-item class="carouselItem">-->
<!--            <img src="https://res.yunkun.cn/miaokou/img/slice_3.png" alt="" />-->
<!--          </el-carousel-item>-->
<!--          <el-carousel-item class="carouselItem">-->
<!--            <img src="https://res.yunkun.cn/miaokou/img/slice_4.png" alt="" />-->
<!--          </el-carousel-item>-->
<!--          <el-carousel-item class="carouselItem">-->
<!--            <img src="https://res.yunkun.cn/miaokou/img/slice_5.png" alt="" />-->
<!--          </el-carousel-item>-->
<!--        </el-carousel>-->
<!--        <div class="pointerList">-->
<!--          <span :class="{ pointer: true, active: activeIndex === 0 }" @click="handleChange(0)"></span>-->
<!--          <span :class="{ pointer: true, active: activeIndex === 1 }" @click="handleChange(1)"></span>-->
<!--          <span :class="{ pointer: true, active: activeIndex === 2 }" @click="handleChange(2)"></span>-->
<!--          <span :class="{ pointer: true, active: activeIndex === 3 }" @click="handleChange(3)"></span>-->
<!--          <span :class="{ pointer: true, active: activeIndex === 4 }" @click="handleChange(4)"></span>-->
<!--        </div>-->
      </div>
    </div>
    <div class="areaWrapper questionWrapper">
      <div class="areaContainer">
        <div class="areaTitle">常见问题</div>
        <div class="questionCardList">
          <div
            :class="{ questionCard: true, active: active === index }"
            @click="setActive(index)"
            v-for="(question, index) in questions"
            :key="index"
          >
            <div class="cardTitle">
              <div class="titleLeft">
                <span class="circle"></span>
                <span class="title">{{ question.title }}</span>
              </div>
              <span class="icon"></span>
            </div>
            <div class="cardContent" v-html="question.content"></div>
          </div>

        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footerContainer">
        <div class="footerContainerLeft">
          <div class="logoBox">
            <img src="@/assets/img/footer_logo.png" alt="" class="logo" />
            <img src="@/assets/img/footer_subtitle.png" alt="" class="subtitleImg" />
          </div>
          <div class="navListContainer">
            <div class="navListBox">
              <div class="navListTitle">轻秒产品</div>
              <nav class="navList">
                <a href="https://img.geshi.cn" target="_blank" class="navItem">img.geshi.cn - 图片压缩</a>
                <a href="https:///pdf.geshi.cn" target="_blank" class="navItem">pdf.geshi.cn - PDF在线</a>
                <a href="https://www.yinpin.cn" target="_blank" class="navItem">yinpin.cn - 轻秒音频转换器</a>
              </nav>
            </div>
            <div class="navListBox">
              <div class="navListTitle">帮助中心</div>
              <nav class="navList">
                <a href="https://txc.qq.com/products/619647/" target="_blank" class="navItem">常见问题</a>
                <a href="https://www.wenjuan.com/s/j6VvQrz/#" target="_blank" class="navItem">投诉建议</a>
              </nav>
            </div>
            <div class="navListBox">
              <div class="navListTitle">
                <a href="/about" style="color: rgba(235, 235, 235, 1);font-weight: 500; font-size: 16px; text-decoration: none">关于我们</a>
              </div>
              <nav class="navList">
                <a :href="appConfig.footer?.agreement" target="_blank" class="navItem">用户协议</a>
                <a :href="appConfig.footer?.privacyPolicy" target="_blank" class="navItem">隐私政策</a>
              </nav>
            </div>
          </div>
        </div>
        <div class="footerContainerRight">
          <div class="qrCodeBox">
            <img :src="appConfig.contact?.customer" alt="" class="qrCode" />
            <div class="boxTitle">微信扫码咨询</div>
          </div>
          <div class="qrCodeBox">
            <img :src="appConfig.contact?.officialAccounts" alt="" class="qrCode" />
            <div class="boxTitle">扫一扫关注轻秒</div>
          </div>
        </div>
      </div>
      <div class="copyright">
        {{appConfig.footer?.copyright}}
        <a href="https://beian.miit.gov.cn/" target="_blank">{{appConfig.footer?.recordNumber}}</a>
        <img src="@/assets/img/footer_icon_police@2x.png" alt="" style="width: 20px;margin-right: -2px;margin-left: 60px;">
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32058302003509" target="_blank"
          >{{appConfig.footer?.beian}}</a
        >
      </div>
    </div>
    <div class="contactBox">
      <img src="@/assets/img/service.svg" alt="" class="contact_icon" />
      <div class="contact_qr_code_box">
        <img src="https://res.yunkun.cn/img_geshicn/img/contact_qr_code.png" class="contact_qr_code" alt="" />
        <div class="txt">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 10 2" fill="none">
            <path d="M0 1H10" stroke="#A0ACBB" stroke-width="0.5" stroke-dasharray="2 2" />
          </svg>
          <span>微信扫码咨询</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 10 2" fill="none">
            <path d="M0 1H10" stroke="#A0ACBB" stroke-width="0.5" stroke-dasharray="2 2" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
import { directive, Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import StepSwiper from '@/components/StepSwiper/index.vue'
import {appConfig} from "@/config";

export default {
  name: 'DefaultLayout',
  components: {
    Header,
    StepSwiper,
    Swiper,
    SwiperSlide,
    directive,
  },
  props: {
    steps: Array,
    stepsAreaTitle: String,
    featuresAreaTitle: String,
    features: Array
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      active: -1,
      appConfig: appConfig,
      questions: [
        {
          title: '可以免费试用吗？',
          content:
            '可以预览处理后图片的效果、图片像素、内存大小等参数，只有下载时需要付费，用户可以根据实际需要选择是否付费下载。\n'
        },
        {
          title: '如何申请电子发票？',
          content:
            '访问 <a href="https://www.wenjuan.com/s/UZBZJvd4Mx/" target="_blank" style="color:#165dff"}">https://www.wenjuan.com/s/UZBZJvd4Mx/</a>，登记开票资料，申请发票后的3 个工作日内发送到登记邮箱，请注意查收。开票项目为：技术服务费。'
        },
        {
          title: '图片处理和下载时间需要多少？',
          content:
            '图片处理和下载的时间受图片大小、电脑配置以及网速等影响，一般处理时长几秒钟，如果长时间（如超过一分钟）未能下载，可能是网络不稳定导致下载中断，可以重新上传处理。'
        },
        {
          title: '图片压缩如何设置参数？',
          content:
            '当您上传图片进行压缩时，我们默认设置为“清晰优先”，以确保在压缩过程中尽可能保持图片质量。如果您的需求更倾向于减小文件大小，可以在选项中选择“缩小优先”。此外，您还可以手动调整压缩比例或指定目标大小，以便更精确地控制压缩效果。'
        }
      ],
      swiperOptions: {
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        },
        delay: 3500,
        speed: 800,
        loop: true,
        observer: true,
        observeParents: true,
        slidesPerView: 3,
        spaceBetween: 12
      },
      activeIndex: 0,
    }
  },
  methods: {
    setActive(index) {
      this.active = this.active === index ? -1 : index
    },
    onSwiperChange(index) {
      this.activeIndex = index
    },
    handleChange(index) {
      this.activeIndex = index
      this.$refs.swiper.setActiveItem(index)
    },
  },
  mounted() {
    class BeforeAfter {
      constructor(enteryObject) {
        const beforeAfterContainer = document.querySelector(enteryObject.id)
        if (beforeAfterContainer) {
          const before = beforeAfterContainer.querySelector('.bal-before')
          // const beforeText = beforeAfterContainer.querySelector('.bal-beforePosition');
          // const afterText = beforeAfterContainer.querySelector('.bal-afterPosition');
          const handle = beforeAfterContainer.querySelector('.bal-handle')
          var widthChange = 0

          beforeAfterContainer
                  .querySelector('.bal-before-inset')
                  .setAttribute(
                          'style',
                          'width: ' + beforeAfterContainer.offsetWidth + 'px;'
                  )
          window.onresize = function () {
            beforeAfterContainer
                    .querySelector('.bal-before-inset')
                    .setAttribute(
                            'style',
                            'width: ' + beforeAfterContainer.offsetWidth + 'px;'
                    )
          }
          before.setAttribute('style', 'width: 50%;')
          handle.setAttribute('style', 'left: 50%;')

          //瑙︽懜灞忎簨浠剁洃鍚�
          beforeAfterContainer.addEventListener('touchstart', e => {
            beforeAfterContainer.addEventListener('touchmove', e2 => {
              let containerWidth = beforeAfterContainer.offsetWidth
              let currentPoint = e2.changedTouches[0].clientX

              let startOfDiv = beforeAfterContainer.offsetLeft

              let modifiedCurrentPoint = currentPoint - startOfDiv

              if (
                      modifiedCurrentPoint > 10 &&
                      modifiedCurrentPoint < beforeAfterContainer.offsetWidth - 10
              ) {
                let newWidth = (modifiedCurrentPoint * 100) / containerWidth

                before.setAttribute('style', 'width:' + newWidth + '%;')
                // afterText.setAttribute('style', "z-index: 1;");
                handle.setAttribute('style', 'left:' + newWidth + '%;')
              }
            })
          })

          //榧犳爣绉诲姩浜嬩欢
          beforeAfterContainer.addEventListener('mousemove', e => {
            e.preventDefault()
            let containerWidth = beforeAfterContainer.offsetWidth
            widthChange = e.offsetX
            let newWidth = (widthChange * 100) / containerWidth
            if (e.offsetX >= 0 && e.offsetX <= beforeAfterContainer.offsetWidth) {
              let Wintdh
              // console.log(newWidth);
              if (newWidth > 99) {
                Wintdh = 100
              } else {
                Wintdh = newWidth
              }
              before.setAttribute('style', 'width:' + Wintdh + '%;')
              // afterText.setAttribute('style', "z-index:" + "1;");
              handle.setAttribute('style', 'left:' + Wintdh + '%;')
            }
          })
        }
      }
    }
    new BeforeAfter({
      id: '#best-quality'
    })

    new BeforeAfter({
      id: '#repair'
    })
    new BeforeAfter({
      id: '#bal-repair'
    })
  },
  watch: {
    $route: {
      handler() {
        this.path = this.$route.path
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
<style scoped lang="less">
@import url('./defaultLayout');
</style>
