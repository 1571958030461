import Vue from 'vue'
import Main from './main.vue'

let UploadModalConstructor = Vue.extend(Main)
const UploadModal = (options, props) => {
  options = options || {}
  let data = Object.assign({}, options, {
    afterClose: () => {
      if (typeof options?.afterClose === 'function') {
        options.afterClose()
      }
      // vm.$destroy()
    }
  })
  let instance = new UploadModalConstructor({
    ...props,
    el: document.createElement('div'),
    data: data
  })
  instance.$mount()
  document.body.appendChild(instance.$el)
  instance.visible = true
  return instance
}

export default UploadModal
