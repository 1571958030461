import Vue from 'vue'
import Main from './main.vue'

let VerifyModalConstructor = Vue.extend(Main)
const Verify = (options, props) => {
  options = options || {}
  let data = Object.assign({}, options, {
    afterClose: (vm, res) => {
      if (typeof options?.afterClose === 'function') {
        options.afterClose(res)
      }
      vm.$destroy()
    }
  })
  let instance = new VerifyModalConstructor({
    ...props,
    el: document.createElement('div'),
    data: data
  })
  instance.$mount()
  document.body.appendChild(instance.$el)
  instance.visible = true
  return instance
}

export default Verify
