<template>
  <el-dialog
    class="messageDialogBox"
    :visible.sync="visible"
    width="384px"
    :append-to-body="true"
    :show-close="true"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    :custom-class="'messageDialogContainer'"
    :destroy-on-close="true"
  >
    <div class="messageDialog">
      <div class="titleBox">
        <span class="title" v-html="title"></span>
        <i class="el-icon-close closeIcon" @click="handleClose"></i>
      </div>
      <div class="content" v-html="content"></div>
      <div slot="footer" class="btnGroups" v-if="showLeft || showRight">
        <button class="leftBtn btn" @click="handleClickLeftBtn" v-if="showLeft">
          {{ leftBtnTxt }}
        </button>
        <button class="rightBtn btn" @click="handleClickRightBtn" v-if="showRight">
          {{ rightBtnTxt }}
        </button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'Msg',
  data() {
    return {
      title: '温馨提示',
      visible: false,
      content: '',
      leftBtnTxt: '取消',
      rightBtnTxt: '确定',
      showLeft: true,
      showRight: true,
      onClickLeftBtn() {},
      onClickRightBtn() {},
      onClose() {},
      afterClose() {}
    }
  },
  methods: {
    hide() {
      this.visible = false
      if (typeof this.afterClose === 'function') {
        this.afterClose(this)
      }
    },
    handleClose() {
      this.hide()
      if (typeof this.onClose === 'function') {
        this.onClose(this)
      }
    },
    handleClickLeftBtn() {
      if (typeof this.onClickLeftBtn === 'function') {
        this.onClickLeftBtn(this)
      }
      this.hide()
    },
    handleClickRightBtn() {
      if (typeof this.onClickRightBtn === 'function') {
        this.onClickRightBtn(this)
      }
      this.hide()
    }
  }
}
</script>
<style scoped lang="less">
@import url('./main.less');
</style>
