<template>
  <button @click="onLogout" class="logoutBtn">
    <slot>退出登录</slot>
  </button>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'LogoutButton',
  components: {},
  props: {},
  data() {
    return {}
  },
  methods: {
    ...mapActions(['logout']),
    onLogout() {
      this.logout()
    }
  },
  created() {}
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
