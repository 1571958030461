<template>
  <el-upload
    class="file-uploader"
    action=""
    :accept="finalAccept"
    :limit="limit"
    :show-file-list="false"
    :multiple="true"
    :before-upload="beforeUpload"
    :on-exceed="handleExceed"
    :disabled="disabled"
  >
    <slot></slot>
  </el-upload>
</template>
<script>
export default {
  name: 'UploadFileBtn',
  props: {
    accept: {
      type: String,
      default: '.png,.jpg,.jpeg,.bmp,.gif,.webp'
    },
    limit: {
      type: Number,
      default: 2
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDrag: false
    }
  },
  methods: {
    beforeUpload(file) {
      this.$emit('onSuccess', file)
      return false
    },
    handleExceed(files) {
      this.$emit('onExceed', files)
    }
  },
  computed: {
    finalAccept() {
      return this.accept + ',' + this.accept.toUpperCase()
    }
  }
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
