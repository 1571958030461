import control, { rotateIcon } from '@/core/control'
import { throttle } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import mitt from 'mitt'
import {saveAs} from 'file-saver'
export const EditorEvent = {
    CHANGE_ZOOM: 'change:zoom',
    RESIZE: 'resize',
    HISTORY_CHANGED: 'history:changed',
    UNDO: 'history:undo',
    REDO: 'history:redo',
    RELOAD: 'history:reload',
    SELECTED: 'selected'
}

export const Format = {
    PNG: 'png',
    JPG: 'jpg'
}

export class Editor {
    constructor(canvasElement, workspaceEl, options = {}) {
        this.options = options
        this.canvasElement = canvasElement
        this.workspaceEl = workspaceEl
        this.emitter = mitt()
        this.canMove = options?.config?.canMove || false // 是否可以拖动画布
        this.state = {
            saveLen: 0, // 保存每一步的数据 saveOperateList 的长度
            deleLen: 0, // 需要删除每一步的数据 deleteOperateList 的长度
            operIndex: -1 // 操作的 Index 的值
        }
        this.saveOperateList = [] // 保存的数据，存的值为每一步的 json 数据
        this.deleteOperateList = [] // 需要删除每一步的数据列表，存的值为 saveOperateList 的每一步的 index 的值
        this.lastActiveObject = null // 上一次选择的元素
        control()
        this.init()
    }

    async init() {
        let { width, height, source: src } = this.options
        this.canvas = null
        this.workspace = null
        this.resizeObserver?.disconnect()
        this.resizeObserver = null

        this.canvas = this.createEditor(this.canvasElement, this.workspaceEl?.offsetWidth, this.workspaceEl?.offsetHeight)
        this.workspace = await this.createWorkspace(width, height)
        this.background = await this.createBackground(width, height, this.options?.bgColor)
        this.targetImg = await this.createImg(src, width, height)
        this.watermark = await this.createWatermark(width, height)
        this.canvas.clipPath = this.workspace
        this.canvas.add(this.workspace)
        this.canvas.add(this.background)
        this.canvas.add(this.targetImg)
        this.canvas.add(this.watermark)
        // , this.background, this.targetImg, this.watermark
        this.canvas.moveTo(this.targetImg, 3)
        this.canvas.moveTo(this.background, 1)
        this.canvas.setActiveObject(this.targetImg)
        this.lastActiveObject = this.targetImg
        this.canvas._centerObject(this.targetImg, this.workspace.getCenterPoint())
        this.canvas.bringToFront(this.watermark)
        this.canvas.renderAll()
        await this._initResizeObserve()
        await this.bindEvent()
        await this._history()
        await this._save()
    }

    /**
     * @description 创建画布
     */
    createEditor(canvasElement) {
        return new fabric.Canvas(canvasElement, {
            stopContextMenu: true,
            preserveObjectStacking: true,
            controlsAboveOverlay: true,
            centeredScaling: true,
            selection: false
        })
    }

    createWorkspace(width, height) {
        return new Promise(resolve => {
            const bg =
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPjwxX8GPEBOThyfNAPjqAHDIgz+//+PNx08evQSfzoYNYCBceiHAQBwplU57bYalAAAAABJRU5ErkJggg=='
            let workspace = new fabric.Rect({
                id: 'workspace',
                width: width,
                height: height,
                selectable: false,
                hoverCursor: 'default',
                backgroundImageStretch: false
            })
            fabric.util.loadImage(bg, img => {
                // 创建一个新的 Pattern 对象
                let pattern = new fabric.Pattern({
                    source: img,
                    repeat: 'repeat'
                })

                workspace.set({
                    fill: pattern
                })
                resolve(workspace)
            })
        })

        // this.canvas.add(this.workspace)
        // this.canvas.clipPath = this.workspace
        // this.canvas.requestRenderAll()
    }

    createWatermark(width, height) {
        return new Promise(resolve => {
            // const bg = '/watermark.svg'
            const bg = '/watermark.png'
            let watermark = new fabric.Rect({
                id: 'watermark',
                width: width,
                height: height,
                selectable: false,
                scaleX: 1,
                scaleY: 1,
                evented: false, // 允许事件穿透，可以选中后面的元素
                backgroundImageStretch: false
            })
            fabric.util.loadImage(bg, img => {
                // 创建一个新的 Pattern 对象
                this.watermarkPattern = new fabric.Pattern({
                    source: img,
                    repeat: 'repeat',
                    hoverCursor: 'default'
                })

                watermark.set({
                    fill: this.watermarkPattern
                })
                resolve(watermark)
                // this.canvas.add(this.watermark)
                // this.canvas.bringToFront(this.watermark)
                // this.canvas.renderAll()
            })
        })
    }

    createImg(src, width, height) {
        return new Promise(resolve => {
            // axios.get(src, {responseType: 'arraybuffer'}).then(response => {
            //   var base64Data = 'data:image/png;base64,' + btoa(
            //           new Uint8Array(response.data).reduce(function (data, byte) {
            //             return data + String.fromCharCode(byte);
            //           }, '')
            //
            //   );
            //   new fabric.Image.fromURL(
            //           base64Data,
            //           oImg => {
            //             let scale = this._calculateScale(
            //                     {
            //                       width: width,
            //                       height: height
            //                     },
            //                     {
            //                       width: oImg.width,
            //                       height: oImg.height
            //                     }
            //             )
            //             oImg.set({
            //               id: 'targetImg',
            //               scaleX: scale,
            //               scaleY: scale
            //             })
            //             resolve(oImg)
            //           },
            //           {
            //             crossOrigin: 'anonymous'
            //           }
            //   )
            // }).catch(e => {
            //   console.log(e)
            // })
            // let img = new Image()
            // img.setAttribute("crossorigin", "anonymous");
            // img.src = src
            // img.onload = () => {
            //   let oImg =new fabric.Image(
            //           img,
            //           {
            //             crossOrigin: 'anonymous'
            //           }
            //   )
            //   let scale = this._calculateScale(
            //           {
            //             width: width,
            //             height: height
            //           },
            //           {
            //             width: oImg.width,
            //             height: oImg.height
            //           }
            //   )
            //   oImg.set({
            //     id: 'targetImg',
            //     scaleX: scale,
            //     scaleY: scale
            //   })
            //   resolve(oImg)
            //
            // }
            new fabric.Image.fromURL(
                src,
                oImg => {
                    let scale = this._calculateScale(
                        {
                            width: width,
                            height: height
                        },
                        {
                            width: oImg.width,
                            height: oImg.height
                        }
                    )
                    oImg.set({
                        id: 'targetImg',
                        scaleX: scale,
                        scaleY: scale
                    })
                    resolve(oImg)
                },
                {
                    crossOrigin: 'anonymous'
                }
            )
        })
    }

    // 创建背景
    createBackground(width, height, bgColor=`rgba(255,255,255,0)`) {
        let background = new fabric.Rect({
            id: 'background',
            width: width,
            height: height,
            fill: bgColor
        })
        return background

        // this.canvas.add(this.background)
        // this.canvas.moveTo(this.background, 1)
        // this.canvas.renderAll()
    }

    setBgImg(source) {
        // 先把画布上的背景元素删除
        const objects = this.canvas.getObjects()
        objects.forEach(item => {
            if (item.id === 'background' || item.id === 'backgroundBg') {
                this.canvas.remove(item)
            }
        })
        this.workspace = this.canvas.getObjects().find(item => item.id === 'workspace')
        // 重新添加背景元素
        new fabric.Image.fromURL(
            source,
            oImg => {
                const scale = this._calculateMaxScale(
                    {
                        width: this.workspace.width,
                        height: this.workspace.height
                    },
                    {
                        width: oImg.width,
                        height: oImg.height
                    }
                )

                oImg.set({
                    id: 'backgroundBg',
                    scaleX: scale,
                    scaleY: scale
                })

                this.canvas.add(oImg)
                this.canvas._centerObject(oImg, this.workspace.getCenterPoint())
                oImg.moveTo(1)
                this.canvas.setActiveObject(oImg)
                this.lastActiveObject = oImg
                this.canvas.renderAll()

                this._save() // 图片加载好后保存一次
            },
            {
                crossOrigin: 'anonymous'
            }
        )
    }

    _calculateMaxScale(origin, target) {
        const originWidth = origin.width
        const originHeight = origin.height
        const targetWidth = target.width
        const targetHeight = target.height

        if (originWidth / originHeight > targetWidth / targetHeight) {
            return originWidth / targetWidth
        }
        return originHeight / targetHeight
    }

    _calculateScale(origin, target) {
        const originWidth = origin.width
        const originHeight = origin.height
        const targetWidth = target.width
        const targetHeight = target.height
        // 如果target的width和height都没有超过origin的width和height，则直接返回1
        if (targetWidth <= originWidth && targetHeight <= originHeight) {
            return 1
        }
        // 按照宽度
        if (originWidth / originHeight < targetWidth / targetHeight) {
            return originWidth / targetWidth
        } // 按照宽度缩放
        return originHeight / targetHeight
    }

    _getScale() {
        return this._calculateScale(
            {
                width: this.workspaceEl.offsetWidth,
                height: this.workspaceEl.offsetHeight
            },
            {
                width: this.options.width,
                height: this.options.height
            }
        )
    }

    /**
     * 设置画布中心到指定对象中心点上
     * @param {Object} obj 指定的对象
     */
    setCenterFromObject(obj) {
        const { canvas } = this
        const objCenter = obj.getCenterPoint()
        const viewportTransform = canvas.viewportTransform
        if (canvas.width === undefined || canvas.height === undefined || !viewportTransform) return
        viewportTransform[4] = canvas.width / 2 - objCenter.x * viewportTransform[0]
        viewportTransform[5] = canvas.height / 2 - objCenter.y * viewportTransform[3]
        canvas.setViewportTransform(viewportTransform)
        canvas.renderAll()
    }

    // 初始化监听器
    _initResizeObserve() {
        this.resizeObserver?.disconnect()
        this.resizeObserver = null
        this.resizeObserver = new ResizeObserver(
            throttle(() => {
                this.auto()
            }, 50)
        )
        this.resizeObserver.observe(this.workspaceEl)
    }

    setZoomAuto(scale) {
        const { workspaceEl } = this
        const width = workspaceEl.offsetWidth
        const height = workspaceEl.offsetHeight

        try {
            this.canvas.setWidth(width)
            this.canvas.setHeight(height)
            const center = this.canvas.getCenter()
            this.canvas.setViewportTransform(fabric.iMatrix.concat())
            this._emit(EditorEvent.CHANGE_ZOOM, scale)
            this.canvas.zoomToPoint(new fabric.Point(center.left, center.top), scale)

            if (!this.workspace) return
            this.setCenterFromObject(this.workspace)

            // 超出画布不展示
            this.workspace.clone(cloned => {
                this.canvas.clipPath = cloned
                this.canvas.requestRenderAll()
            })
        } catch (e) {}
    }

    auto() {
        const scale = this._getScale()
        this.setZoomAuto(scale - 0.08)
    }

    setSize(width, height) {
        this.options.width = width
        this.options.height = height
        // 重新设置workspace
        this.workspace = this.canvas.getObjects().find(item => item.id === 'workspace')
        this.workspace.set('width', width)
        this.workspace.set('height', height)

        this.canvas.getObjects().forEach(item => {
            if (item.id !== 'workspace' && item.id !== 'background' && item.id !== 'backgroundBg') {
                let scale = this._calculateScale(
                    {
                        width: width,
                        height: height
                    },
                    {
                        width: item.width,
                        height: item.height
                    }
                )
                item.set({
                    scaleX: scale,
                    scaleY: scale
                })
                this.canvas._centerObject(item, this.workspace.getCenterPoint())
            }
            if (item.id === 'background') {
                item.set({
                    width: width,
                    height: height
                })
            }

            if (item.id === 'backgroundBg') {
                const scale = this._calculateMaxScale(
                    {
                        width: width,
                        height: height
                    },
                    {
                        width: item.width,
                        height: item.height
                    }
                )

                item.set({
                    scaleX: scale,
                    scaleY: scale
                })
                this.canvas._centerObject(item, this.workspace.getCenterPoint())
            }
            if (item.id === 'watermark') {
                const scale = this._calculateScale(
                    {
                        width: width,
                        height: height
                    },
                    {
                        width: item.width,
                        height: item.height
                    }
                )
                item.set({
                    width: width,
                    height: height,
                    scaleX: 1,
                    scaleY: 1
                })

                this.canvas._centerObject(item, this.workspace.getCenterPoint())
            }
        })

        this.auto()
        this._save()
    }

    /**
     * @description 设置背景颜色
     * @param color
     */
    setBgColor(color) {
        // 先把画布上的背景元素删除
        const objects = this.canvas.getObjects()
        this.workspace = this.canvas.getObjects().find(item => item.id === 'workspace')
        objects.forEach(item => {
            if (item.id === 'backgroundBg') {
                this.canvas.remove(item)

                this.background = new fabric.Rect({
                    id: 'background',
                    width: this.workspace.width,
                    height: this.workspace.height,
                    fill: color
                })

                this.canvas.add(this.background)
                this.background.moveTo(1)
                this.canvas.setActiveObject(this.background)
                this.lastActiveObject = this.background
            } else if (item.id === 'background') {
                item.set({
                    fill: color
                })
                this.canvas.setActiveObject(item)
                this.lastActiveObject = item
            }
        })

        this.canvas.renderAll()
        this._save()

        // const backgroundIns = this.canvas.getObjects().find((item) => item.id === 'background');
        // backgroundIns.set('fill', color);
        // this.canvas.renderAll();
    }

    bindEvent() {
        this._bindWheel()
        /**
         * @description 旋转时，实时更新旋转控制图标
         */
        this.canvas.on('object:rotating', event => {
            const body = this.canvas.lowerCanvasEl.nextSibling
            const angle = this.canvas.getActiveObject()?.angle?.toFixed(2)
            if (angle === undefined) return
            switch (event.transform?.corner) {
                case 'mtr':
                    body.style.cursor = rotateIcon(Number(angle) + 45)
                    break
                default:
                    break
            } // 设置四角旋转光标
        })

        this.canvas.on('mouse:down', opt => {
            const evt = opt.e
            if (evt.altKey === true || this.canMove) {
                this.isDragging = true
                this.selection = false
                this.lastPosX = evt.clientX
                this.lastPosY = evt.clientY
            }

            if (!opt.target) {
                this.canvas.setActiveObject(this.lastActiveObject);
            } else {
                if (opt.target?.id === 'targetImg' || opt.target?.id === 'background' || opt.target?.id === 'backgroundBg' || opt.target?.id.indexOf('_clone') > -1) {
                    this.lastActiveObject = opt.target
                } else {
                    this.canvas.setActiveObject(this.lastActiveObject);
                }
            }
        })
        this.canvas.on('mouse:move', opt => {
            if (this.isDragging) {
                const e = opt.e
                let vpt = this.canvas.viewportTransform
                vpt[4] += e.clientX - this.lastPosX
                vpt[5] += e.clientY - this.lastPosY
                this.canvas.requestRenderAll()
                this.lastPosX = e.clientX
                this.lastPosY = e.clientY
                this.canvas.setCursor('grabbing')
            } else {
                if (this.canMove) {
                    this.canvas.setCursor('grab')
                }
            }
        })
        this.canvas.on('mouse:up', opt => {
            this.canvas.setViewportTransform(this.canvas.viewportTransform)
            this.isDragging = false
            this.selection = true
            if (this.canMove) {
                this.canvas.setCursor('grab')
            }
        })

        this.canvas.on('selection:created', opt => {
            this._emit(EditorEvent.SELECTED, {
                currentSelect: opt.selected[0]?.id
            })
        })
        this.canvas.on('selection:updated', opt => {
            this._emit(EditorEvent.SELECTED, {
                currentSelect: opt.selected[0]?.id
            })
        })
    }

    /**
     * @descriptioin 监听画布缩放
     */
    _bindWheel() {
        this.canvas.on('mouse:wheel', opt => {
            const delta = opt.e.deltaY
            let zoom = this.canvas.getZoom()
            zoom *= 0.999 ** delta
            if (zoom > 20) zoom = 20
            if (zoom < 0.01) zoom = 0.01

            this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom)
            opt.e.preventDefault()
            opt.e.stopPropagation()
            this._emit(EditorEvent.CHANGE_ZOOM, zoom)
        })
    }

    /**
     * 放大
     */
    big() {
        let zoomRatio = this.canvas.getZoom()
        zoomRatio += 0.05
        const center = this.canvas.getCenter()
        this._emit(EditorEvent.CHANGE_ZOOM, zoomRatio)
        this.canvas.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio)
    }

    /**
     * 缩小
     */
    small() {
        let zoomRatio = this.canvas.getZoom()
        zoomRatio -= 0.05
        const center = this.canvas.getCenter()
        zoomRatio = zoomRatio < 0 ? 0.01 : zoomRatio
        this._emit(EditorEvent.CHANGE_ZOOM, zoomRatio)
        this.canvas.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio)
    }

    _getSaveOption(format=Format.PNG) {
        const workspace = this.canvas.getObjects().find(item => item.id === 'workspace')
        const { left, top, width, height } = workspace
        const option = {
            name: 'New Image',
            format: format === Format.JPG ? 'jpeg' : format,
            quality: 1,
            width: width,
            height: height,
            left: left + 0.5,
            top: top + 0.5
        }
        return option
    }

  _cropImage(options) {
    const {src, cropStartX=0, cropStartY=0, type='image/png', quality=1, backgroundColor='transparent'} = options
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas')
      canvas.style.backgroundColor = 'transparent'
      canvas.style.border = 'none'
      const ctx = canvas.getContext('2d')
      const img = new Image()
      img.src = src

      img.onload = function () {
        const cropEndX = img.width - cropStartX
        const cropEndY = img.height - cropStartY
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";
        canvas.width = img.width;
        canvas.height = img.height;
        // ctx.globalCompositeOperation = "destination-in";
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.fillStyle = 'rgba(0,0,0,0)'
        ctx.fillRect(0,0,canvas.width, canvas.height)
        ctx.drawImage(img, cropStartX, cropStartY, cropEndX, cropEndY, 0, 0, img.width + 1, img.height + 1)
        let result = canvas.toDataURL(type, quality)
        // canvas.toBlob((blob) => {
        //   console.log(1231)
          resolve(result)
        // }, type, 1)
      }
      img.onerror = function (e) {
        resolve(e)
      }
    })
  }

    /**
     * @description 保存图片
     */
    saveImg(format = Format.PNG) {
        const option = this._getSaveOption(format)
        const beforeViewportTransform = this.canvas.viewportTransform
        this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0])
        const workspace = this.canvas.getObjects().find(item => item.id === 'workspace')
        const originFill = workspace.fill
        workspace.set({
            fill: 'transparent'
        })
        const watermark = this.canvas.getObjects().find(item => item.id === 'watermark')
        const originWatermarkFill = watermark.fill
        watermark.set({
            fill: 'transparent'
        })
        const dataUrl = this.canvas.toDataURL(option)
        this._cropImage({
          src: dataUrl,
          cropStartX: 2,
          cropStartY: 2,
          type: format === Format.PNG ? 'image/png' : 'image/jpeg',
          quality: 1
        }).then(res => {
          saveAs(res, `${uuid()}.${format}`)
        }).finally(() => {
          workspace.set({
            fill: originFill
          })
          watermark.set({
            fill: originWatermarkFill
          })

          this.canvas.setViewportTransform(beforeViewportTransform)
          this.canvas.renderAll()
        })

        // this._drawImageFromUrl(dataUrl, 1, `${uuid()}.${format}`, option)




        // downFile(dataUrl, format, () => {
        //   workspace.set({
        //     fill: originFill
        //   })
        //   watermark.set({
        //     fill: originWatermarkFill
        //   })
        //
        //   this.canvas.setViewportTransform(beforeViewportTransform)
        //   this.canvas.renderAll()
        // })
    }

    /**
     * @description 翻转
     * @param type X | Y
     */
    flip(type) {
        const activeObject = this.canvas.getActiveObject()
        if (activeObject) {
            activeObject.set(`flip${type}`, !activeObject[`flip${type}`]).setCoords()
            this.canvas.requestRenderAll()
            this._save() // 保存一次
        }
    }

    // 单个对象复制
    _copyObject(activeObject) {
        // 间距设置
        const grid = 10
        const canvas = this.canvas

        activeObject?.clone(cloned => {
            if (cloned.left === undefined || cloned.top === undefined) return
            canvas.discardActiveObject()
            // 设置位置信息
            cloned.set({
                left: cloned.left + grid,
                top: cloned.top + grid,
                id: `${uuid()}_clone`
            })
            canvas.add(cloned)
            canvas.setActiveObject(cloned)
            this.lastActiveObject = cloned
            canvas.requestRenderAll()
            this._save()
        })
    }

    /**
     * @description 复制
     * @param paramsActiveObject
     */
    clone(paramsActiveObject) {
        const activeObject = paramsActiveObject || this.canvas.getActiveObject()
        if (!activeObject) return
        this._copyObject(activeObject)
    }

    _emit(type, ...args) {
        const condition = Object.keys(EditorEvent).includes(type) || Object.values(EditorEvent).includes(type)
        if (condition) {
            this.emitter.emit(type, ...args)
        }
    }

    on(type, handler) {
        const condition =
            (Object.keys(EditorEvent).includes(type) || Object.values(EditorEvent).includes(type)) &&
            typeof handler === 'function'
        if (condition) {
            this.emitter.on(type, handler)
        }
    }

    off(type, handler) {
        const condition =
            (Object.keys(EditorEvent).includes(type) || Object.values(EditorEvent).includes(type)) &&
            typeof handler === 'function'
        if (condition) {
            this.emitter.off(type, handler)
        }
    }

    _save() {
        const currentZoom = this.canvas.getZoom()
        this.canvas.set({
            currentZoom: currentZoom
        })
        const json = this.canvas.toJSON(['id', 'evented', 'selectable']) // 保存自定义属性
        if (this.state.deleLen > 0) {
            this.deleteOperateList.some(item => {
                this.saveOperateList[item].del = true
            })
            this.saveOperateList = this.saveOperateList.filter(item => {
                return !item.del
            })
            this.deleteOperateList = []
            this.saveOperateList.push(json)
            this.state.operIndex = this.saveOperateList.length - 1
        } else {
            this.saveOperateList.push(json)
            this.state.operIndex += 1
        }
        this.state.saveLen = this.saveOperateList.length
        this.state.deleLen = this.deleteOperateList.length

        this._historyChangeEvent()
    }

    _history() {
        this.canvas.on({
            'object:modified': () => {
                this._save()
            }
        })
    }

    _historyChangeEvent() {
        this._emit(EditorEvent.HISTORY_CHANGED, {
            hasUndo: this.state.operIndex > 0,
            hasRedo: this.state.operIndex + 1 < this.saveOperateList.length
        })
    }

    _reloadFromJSON(json) {
        this.canvas.loadFromJSON(
            json,
            () => {
                this.auto()
                this.canvas.renderAll()
            },
            (o, item) => {
                if (item.id === 'workspace') {
                    this.workspace = item
                    this.options.width = item.width
                    this.options.height = item.height
                }
            }
        )
    }

    _undoEventAndRedoEvent(type, json) {
        let info = {
            width: 0,
            height: 0,
            backgroundColor: '',
            backgroundImg: ''
        }
        json.objects?.forEach(item => {
            if (item.id === 'workspace') {
                info.width = item.width
                info.height = item.height
            }
            if (item.id === 'background') {
                info.backgroundColor = item.fill
            }
            if (item.id === 'backgroundBg') {
                info.backgroundImg = item.src
            }
        })

        this._emit(EditorEvent.UNDO, info)
    }

    // 上一步
    undo() {
        let json = {}
        if (this.state.operIndex > 0) {
            json = this.saveOperateList[this.state.operIndex - 1]
            this._reloadFromJSON(json)
            this._undoEventAndRedoEvent(EditorEvent.UNDO, json)
            if (this.deleteOperateList.includes(this.state.operIndex - 1)) {
            } else {
                this.deleteOperateList.push(this.state.operIndex)
                this.state.operIndex -= 1
            }
        }
        this.state.saveLen = this.saveOperateList.length
        this.state.deleLen = this.deleteOperateList.length

        this._historyChangeEvent()
    }

    // 下一步
    redo() {
        if (this.state.operIndex + 1 >= this.saveOperateList.length) {
            return
        }
        const json = this.saveOperateList[this.state.operIndex + 1]
        this._reloadFromJSON(json)
        this._undoEventAndRedoEvent(EditorEvent.REDO, json)
        if (this.deleteOperateList.includes(this.state.operIndex + 1)) {
            const index = this.deleteOperateList.indexOf(this.state.operIndex + 1)
            this.deleteOperateList.splice(index, 1)
        } else {
        }
        this.state.operIndex = this.state.operIndex + 1
        this.state.saveLen = this.saveOperateList.length
        this.state.deleLen = this.deleteOperateList.length
        this._historyChangeEvent()
    }

    // 重做
    redoAll() {
        this.deleteOperateList = []
        this.saveOperateList = [this.saveOperateList.shift()]
        this.state.operIndex = 0
        this.state.saveLen = this.saveOperateList.length
        this.state.deleLen = this.deleteOperateList.length
        const json = this.saveOperateList[this.state.operIndex]
        this._reloadFromJSON(json)
        this._undoEventAndRedoEvent(EditorEvent.RELOAD, json)
        this._historyChangeEvent()
    }

    /**
     * @description 销毁实例
     */
    destory() {
        this.canvas.dispose()
    }
}
