<template>
  <div id="app" @contextmenu="disableRightClick">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'APP',
  data() {
    return {
      listenFunc: () => {}
    }
  },
  methods: {
    disableRightClick(e) {
      e.preventDefault()
    }
  },
  async created() {
    let bd = await window?.getAppRuntime?.getBdvid()
    let desktop_bd_vid = bd?.split('bd_')?.[1]?.split('_t_')?.[0]
    let bd_vid =  desktop_bd_vid ?? this.$route.query?.bd_vid
    if (bd_vid) {
      window.localStorage.setItem('bd_vid', bd_vid)
    }

    let { qhclickid } = this.$route.query;
    if (qhclickid) {
      window.localStorage.setItem("qhclickid", qhclickid);
    }
  },
  mounted() {
    this.listenFunc = e => {
      // 阻止浏览器默认行为
      e.preventDefault()
      // 保存事件以备后用
      this.$store.dispatch('setDeferredPrompt', e)
      // 等待用户响应
      this.$store.state.deferredPrompt?.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('用户已同意安装');
        } else {
          console.log('用户拒绝了安装');
        }
        // 重置 deferredPrompt 变量，以便下次触发安装提示时使用
        // that.deferredPrompt = null;
        this.$store.dispatch('setDeferredPrompt', null)
      });
    }
    window.addEventListener('beforeinstallprompt', this.listenFunc)
  },
  beforeDestroy() {
    window.removeEventListener('beforeinstallprompt',this.listenFunc)
  }
}
</script>

<style lang="less"></style>
