export class ImageBase {
  constructor(info, option) {
    this.info = info;
    this.option = option;
  }
  /**
   * Get output image dimension, based on resize param
   * @returns Dimension
   */
  getOutputDimension() {
    return {
      x: 0,
      y: 0,
      width: this.info.width,
      height: this.info.height,
    }
  }
  /**
   * Return original info when process fails
   * @returns
   */
  failResult() {
    return {
      width: this.info.width,
      height: this.info.height,
      blob: this.info.blob,
      src: URL.createObjectURL(this.info.blob),
    };
  }
  /**
   * Get preview image size via option
   * @returns Dimension
   */
  getPreviewDimension() {
    const maxSize = this.option.preview.maxSize;
    if (Math.max(this.info.width, this.info.height) <= maxSize) {
      return {
        x: 0,
        y: 0,
        width: this.info.width,
        height: this.info.height,
      };
    }
    let width, height;
    if (this.info.width >= this.info.height) {
      const rate = maxSize / this.info.width;
      width = maxSize;
      height = rate * this.info.height;
    }
    else {
      const rate = maxSize / this.info.height;
      width = rate * this.info.width;
      height = maxSize;
    }
    return {
      x: 0,
      y: 0,
      width: Math.ceil(width),
      height: Math.ceil(height),
    };
  }
  /**
   * Get preview from native browser method
   * @returns
   */
  async preview() {
    const { width, height, x, y } = this.getPreviewDimension();
    const blob = await this.createBlob(width, height, x, y);
    return {
      width,
      height,
      blob,
      src: URL.createObjectURL(blob),
    };
  }
  async createCanvas(width, height, cropX = 0, cropY = 0) {
    const canvas = new OffscreenCanvas(width, height);
    const context = canvas.getContext("2d");
    const image = await createImageBitmap(this.info.blob);
    context === null || context === void 0 ? void 0 : context.drawImage(image, 0, 0, this.info.width, this.info.height, 0, 0, width, height);
    image.close();
    return { canvas, context };
  }
  /**
   * create OffscreenCanvas from Blob
   * @param width
   * @param height
   * @param quality
   * @param cropX
   * @param cropY
   * @returns
   */
  async createBlob(width, height, quality = 0.6, cropX = 0, cropY = 0) {
    const { canvas } = await this.createCanvas(width, height, cropX, cropY);
    const opiton = {
      type: this.info.blob.type,
      quality,
    };
    return canvas.convertToBlob(opiton);
  }
}
