<template>
  <el-dialog
    class="verifyBox"
    :visible.sync="visible"
    width="430px"
    :append-to-body="true"
    :show-close="false"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    :custom-class="'verifyContainer'"
    @open="onDialogOpen"
    @opened="onDialogOpened"
    @closed="onAfterClose"
    :destroy-on-close="true"
  >
    <div class="verifyMain">
      <div class="mainHeader">
        <div class="title">访问验证</div>
        <i class="el-icon-close closeIcon" @click="handleClickClose"></i>
      </div>
      <div class="verifyMainContainer">
        <div class="tips">亲，这是个机器猖狂的时代，请输入验证码证明咱是个正常人！</div>
        <el-form @submit.native.prevent :model="form" ref="form" :rules="rules">
          <el-form-item prop="code">
            <el-input v-model="form.code" autocomplete="off" placeholder="按下图中的字符填写，不区分大小写"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="code" ref="code" style="width: 100px; height: 40px"></div>
          </el-form-item>
          <div class="refresh" @click="onRefresh">看不清楚？换一个</div>
          <div class="btnGroups">
            <el-button native-type="button">取消</el-button>
            <el-button type="primary" native-type="button" @click="submitForm">确定</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GVerify from '@/utils/verify'
export default {
  name: 'VerifyModal',
  data() {
    return {
      rules: {
        code: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入验证码'))
              } else {
                let flag = this.gVerify.validate(value)
                if (!flag) {
                  callback(new Error('验证码错误'))
                } else {
                  callback()
                }
              }
            },
            trigger: 'submit'
          }
        ]
      },
      form: {
        code: ''
      },
      visible: false,
      afterClose() {},
      onCancel() {},
      status: false
    }
  },
  methods: {
    onRefresh() {
      this.gVerify?.refresh()
    },
    onAfterClose() {
      if (typeof this.afterClose === 'function') {
        this.afterClose(this, {
          status: this.status
        })
      }
    },
    handleClickClose() {
      if (typeof this.onCancel === 'function') {
        this.onCancel()
      }
      this.visible = false
    },
    onDialogOpen() {
      this.status = false
    },
    onDialogOpened() {
      this.gVerify = new GVerify({
        el: '.code'
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.status = true
          this.visible = false
        }
      })
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   let gVerify = new GVerify({
    //     el: '.code'
    //   })
    // })
  }
}
</script>
<style scoped lang="less">
@import url('./main.less');
</style>
